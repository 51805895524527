import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import AnchorLink from "@/components/anchor-link/index.vue";
import heroBg from "../../assets/images/test-bg.png";
import heroBg1 from "../../assets/images/test-bg1.png";
import heroBg3 from "../../assets/images/main-3-bg.png";

export default defineComponent({
  name: "Home page",
  components: { Hero, AnchorLink },
  setup() {
    const heroSlide = [
      {
        title: "Nasarawa State",
        subTitle: "Nigeria's New Investment Frontier",
        image: heroBg,
        link: "why-nasarawa",
      },
      {
        title: "Nasarawa State",
        subTitle: "Boosting Private Sector Participation",
        image: heroBg1,
        link: "investment-opportunities",
      },
      {
        title: "Nasarawa State",
        subTitle: "Innovatively Diversifying Our Economy",
        image: heroBg3,
        link: "investment-opportunities",
      },
    ];

    return {
      heroSlide,
    };
  },
});
