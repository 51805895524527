<template>
  <div id="home-page">
    <Hero :slideContent="heroSlide" />

    <div class="mt-10 max-w-screen-lg mx-auto pb-20 w-9/10">
      <div class="text-nasida-green">
        <h3 class="text-lg md:text-2xl">Who We Are</h3>
        <h1 class="my-6 text-3xl md:text-4xl">A holistic investment agency</h1>
        <p class="text-justify text-nasida-grey">NASIDA is the Nasarawa Investment and Development Agency, created through an act of the Nasarawa State House of Assembly to initiate, promote, facilitate and coordinate investments in Nasarawa State, whether it be public private partnerships, privatization, concessions or commercialization of state owned assets.</p>
      </div>
      <div class="my-10 md:grid md:grid-cols-3 items-center">
        <div class="bg-pale-green rounded-lg p-6 md:col-span-2 md:pr-52 md:py-20">
          <div class="w-16">
            <img src="../../assets/icons/quote-up.svg">
          </div>
          <p class="text-justify my-2">We are adequately positioned to address the fundamental issues that continue to hold back full private sector participation in key sectors of the Nasarawa State economy. We are determined to cut the red tapes and administrative bottlenecks of doing business in Nasarawa State. </p>
          <div class="w-16 ml-auto">
            <img src="../../assets/icons/quote-down.svg">
          </div>
        </div>
        <div class="w-full relative h-72 mt-6 md:-ml-40 md:h-4/5 md:w-96 holistic rounded-lg overflow-hidden">
          <div class="absolute bottom-6 left-6 text-white z-10">
            <h1 class="font-bold text-3xl mb-3">A holistic investment service provider</h1>
            <AnchorLink
              text="Learn More"
              url="departments"
              color="white"
            />
          </div>
          <img
            class="rounded-lg"
            src="../../assets/images/who-we-are.png"
          >
        </div>
      </div>
      <div class="my-10 grid grid-cols-1 gap-6 md:grid-cols-3 md:mt-20">
        <div class="card">
          <div class="card-text">
            <h1 class="font-bold text-3xl">We are a hybrid agency</h1>
            <AnchorLink
              class="link"
              text="Learn More"
              url="departments"
              color="white"
            />
          </div>
          <img
            src="../../assets/images/who-we-are-image.png"
            alt=""
          >
        </div>
        <div class="card">
          <div class="card-text">
            <h1 class="font-bold text-3xl">We do things differently</h1>
            <AnchorLink
              class="link"
              text="Learn More"
              url="infrastructure-and-thematic-holdings"
              color="white"
            />
          </div>
          <img
            src="../../assets/images/who-we-are-image.png"
            alt=""
          >
        </div>
        <div class="card">
          <div class="card-text">
            <h1 class="font-bold text-3xl">We are delivery focused</h1>
            <AnchorLink
              class="link"
              text="Learn More"
              url="factsheet"
              color="white"
            />
          </div>
          <img
            src="../../assets/images/who-we-are-image.png"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Home-page.page.js"></script>
<style src="./Home-page.page.css" scoped lang="css"></style>